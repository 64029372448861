import * as React from "react"
import DocumentationContent from "../../components/DocumentationContent"
import DocumentationSidebar from "../../components/DocumentationSidebar"
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../../components/Layout'
import Header from '../../components/Header'
import Content, { HTMLContent } from '../../components/Content'
import Documentation from '../../components/Documentation'
import { CopyBlock, CodeBlock, atomOneDark, solarizedDark, xt256 } from "react-code-blocks";
import {Image, SlideshowLightbox} from "lightbox.js-react";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import DemoSidebar from "../../components/DemoSidebar";
import Footer from '../../components/Footer'

export default function ShopDemoComponent () {
  const images = [{title: "Photon", src: "https://source.unsplash.com/600x400/?pastel+cloud"}, 
  {title: "Inversion", src: "https://source.unsplash.com/500x400/?pastel+clouds"},
  {title: "Legion", src: "https://source.unsplash.com/600x400/?pastel+sky"},
  {title: "Inversion", src: "https://source.unsplash.com/600x400/?pastel"},
  {title: "Twenty Neue", src: "https://source.unsplash.com/600x400/?sky+pastel"},
  {title: "Geometrik", src: "https://source.unsplash.com/600x400/?dreamy+clouds"},         
  {title: "Achtung", src: "https://source.unsplash.com/600x400/?pastel+fruit"},];
  const showLineNumbers = true;
  const wrapLines = true;
  const codeBlock = false;

  const products = [
    {
      id: 1,
      name: 'Basic Tee',
      href: '#',
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
      imageAlt: "Front of men's Basic Tee in black.",
      price: '$35',
      color: 'Black',
    },
    {
        id: 2,
        name: 'Basic Tee',
        href: '#',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
        imageAlt: "Front of men's Basic Tee in black.",
        price: '$35',
        color: 'White',
      },
      {
        id: 3,
        name: 'Basic Tee',
        href: '#',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
        imageAlt: "Front of men's Basic Tee in black.",
        price: '$35',
        color: 'Navy',
      },
  ]

  return <Layout>
    <section className="section section--gradient font-inter bg_white">
      <Header overrideDarkTheme={true} />
    
        <div className="pt-4">
        <main className="flex-grow">

            <section>
            <div className="max-w-6xl mx-auto px-2 sm:px-2">
                <div className="pt-32 pb-12 md:pt-40 md:pb-20">

                {/* Main content */}
                <div className="md:flex md:justify-between" data-sticky-container>

                    <DemoSidebar />

                    <div className="md:flex-grow  font-inter">

                      {/* <Header /> */}
                      <div className="text-lg text-gray-600">
                        <h1 className="h1 text-gray-900 mb-4 font-bold">Shop Demo</h1>

                        <div className="bg-white">
                            <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
                                <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">Customers also purchased</h2>

                                <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                                {products.map((product) => (
                                    <div key={product.id} className="group relative">
                                    <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                                        <img
                                        src={product.imageSrc}
                                        alt={product.imageAlt}
                                        className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                                        />
                                    </div>
                                    <div className="mt-4 flex justify-between">
                                        <div>
                                        <h3 className="text-sm text-gray-700">
                                            <a href={product.href}>
                                            <span aria-hidden="true" className="absolute inset-0" />
                                            {product.name}
                                            </a>
                                        </h3>
                                        <p className="mt-1 text-sm text-gray-500">{product.color}</p>
                                        </div>
                                        <p className="text-sm font-medium text-gray-900">{product.price}</p>
                                    </div>
                                    </div>
                                ))}
                                </div>
                            </div>
                            </div>

      </div>

      {/* Related content */}
      <div className="mt-8">
        <h3 className="h3 mb-8 font-semibold">Related</h3>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="#0">
          <div>
            <div className="text-normal font-medium mb-1">Folder components</div>
            <div className="text-sm text-gray-600">Learn more about orci ac auctor augue mauris augue neque gravida.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="#0">
          <div>
            <div className="text-normal font-medium mb-1">Deploy hooks</div>
            <div className="text-sm text-gray-600">Learn more about risus nullam eget felis eget nunc.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
      </div>

      {/* <Feedback /> */}

    </div>

                </div>

                </div>
            </div>
            </section>

            </main>
        </div>

    </section>
    <Footer overrideDarkTheme={true} />
    </Layout>
    
}